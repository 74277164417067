// extracted by mini-css-extract-plugin
export var Column = "AlternatingLayout-module--Column--98e5e";
export var ContentColumn = "AlternatingLayout-module--ContentColumn--afa71";
export var ImageRow = "AlternatingLayout-module--ImageRow--c131d";
export var ReactCollapseCollapse = "AlternatingLayout-module--ReactCollapse--collapse--43954";
export var Row = "AlternatingLayout-module--Row--9f06a";
export var active = "AlternatingLayout-module--active--1057f";
export var contactDetails = "AlternatingLayout-module--contactDetails--799b2";
export var contactGrid = "AlternatingLayout-module--contactGrid--850e6";
export var expanded = "AlternatingLayout-module--expanded--a2dbc";
export var glossaryDescription = "AlternatingLayout-module--glossary-description--4dd74";
export var glossaryFadeIn = "AlternatingLayout-module--glossaryFadeIn--3ab3e";
export var glossaryTerm = "AlternatingLayout-module--glossary-term--b4e51";
export var impressum = "AlternatingLayout-module--impressum--3aa83";
export var layout = "AlternatingLayout-module--layout--8aad7";
export var legal = "AlternatingLayout-module--legal--39ce0";
export var readMoreButton = "AlternatingLayout-module--readMoreButton--6827d";
export var withFadeOut = "AlternatingLayout-module--withFadeOut--caa04";