import React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/Layout/Layout";
import * as classes from "./alternating.module.scss";
import AlternatingLayout from "../../components/AlternatingLayout/AlternatingLayout";
import { getImage, GatsbyImage } from "gatsby-plugin-image";

const alternating = ({ data }) => {
  const alternatingData = data.alternating.frontmatter;
  const filteredSubsections = alternatingData.children.filter(
    sub => !(sub?.internal.mediaType !== "text/markdown" || !sub)
  );
  const subsections = filteredSubsections.map(subsection => {
    const data = subsection.childMarkdownRemark;
    return {
      title: data.frontmatter.title,
      html: data.html,
      image: getImage(data.frontmatter.featured_image?.childImageSharp),
    };
  });

  return (
    <Layout>
      {/* top content */}
      {alternatingData.featured_image ? (
        <GatsbyImage image={getImage(alternatingData.featured_image.childImageSharp)} />
      ) : null}
      {data.alternating.html !== "" && data.alternating.html ? (
        <div className={classes.section}>
          <h1>{alternatingData.title}</h1>
          <div dangerouslySetInnerHTML={{ __html: data.alternating.html }} />
        </div>
      ) : null}

      {/* Alternating sections */}
      <AlternatingLayout items={subsections} />
    </Layout>
  );
};

export const query = graphql`
  query($absolutePath: String) {
    alternating: markdownRemark(fileAbsolutePath: { eq: $absolutePath }) {
      html
      frontmatter {
        featured_image {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH),
          }
        }
        children {
          internal {
            mediaType
          }
          childMarkdownRemark {
            html
            frontmatter {
              title
              featured_image {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH),
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default alternating;
